@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
}

input,
button {
  font-family: "Inter", sans-serif;
}

.visitform {

    .Title {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      margin: 20px 0px;
      font-family: "Playfair Display", serif;
      margin-top: 50px;
      margin-bottom: 30px;
    }
  
    input,select {
      width: 300px;
      font-size: 20px;
      border: 1px solid #dbdbdb;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      outline-color: #458af0;
    }
    .con {
      width: 320px;
      font-size: 15px;
      font-weight: 600;
      border: none;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      background: #458af0;
      color: white;
      margin-top: 20px;
      cursor: pointer;
    }
  

    textarea {
      width: 300px;
      font-size: 20px;
      height: 300px;
      border: 1px solid #dbdbdb;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      outline-color: #458af0;
  }

  .loader {
    width: 100%;
    height: 100vh;
    background: #0000004a;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader img {
    width: 35px;
}
  }
  
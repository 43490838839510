.sites_page {
    .Title2 {
      font-size: 25px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      margin-top: 30px;
      margin-bottom: 30px;
      margin-left: 30px;
    }
  
    .boxes {
      display: flex;
      flex-wrap: wrap;
      margin: 0px 20px;
    }
  
    .box {
      width: 50%;
      max-width: 150px;
      display: block;
      margin: 20px 10px;
      cursor: pointer;
      text-decoration: none;
    }
    .box:hover {
      opacity: 0.7;
    }
    .box:active {
      opacity: 0.7;
    }
  
    .box img {
      width: 100%;
    }
  
    .box .sitename {
      font-size: 15px;
      font-weight: 500;
      margin-top: 5px;
      color: black;
    }
  }
  
.main_users_page {
    .Title2 {
      font-size: 25px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      margin: 30px 30px;
      margin-bottom: 15px;
    }
  
    .boxes {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  
    .box {
      width: 45%;
      max-width: 150px;
      display: block;
      margin: 20px 10px;
      cursor: pointer;
      text-decoration: none;
    }
    .box:hover {
      opacity: 0.7;
    }
    .box:active {
      opacity: 0.7;
    }
  
    .box img {
      width: 100%;
      height: 150px;
    }
  
    .box .sitename {
      font-size: 15px;
      font-weight: 500;
      margin-top: 5px;
      color: black;
    }
  
    .create {
      width: 45%;
      min-height: 160px;
      max-width: 150px;
      display: block;
      margin: 20px 10px;
      cursor: pointer;
      text-decoration: none;
      border: none;
      background: #458af0;
      color: white;
      font-size: 15px;
    }
  
    .popup_user_back {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: #00000017;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .popup_user {
      width: 80%;
      max-width: 400px;
      padding: 15px;
      background: white;
      border-radius: 5px;
    }
  
    .popup_user .title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  
    input {
      width: 95%;
      font-size: 15px;
      margin: 10px 0px;
      padding: 5px;
    }
  
    select {
      width: calc(95% + 10px);
      font-size: 15px;
      margin: 10px 0px;
      padding: 5px;
    }

    .popup_user button {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 20px;
      margin-right: 10px;
      color: white;
      margin-top: 10px;
    }
    .popup_user button:last-child {
      background: #d64848;
    }

        
    .loader {
      width: 100%;
      height: 100vh;
      background: #0000004a;
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .loader img {
      width: 35px;
  }
  }
  
.admin_Login_page {
    a {
      text-decoration: none;
    }
    .nav {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #cccccc;
    }
    .Logo {
      font-size: 20px;
      font-weight: 800;
      text-align: center;
      margin-left: 20px;
      color: #458af0;
    }
  
    .nav span {
      font-size: 15px;
      margin-left: 5px;
      color: #555555;
    }
    .Title {
      font-size: 30px;
      font-weight: 600;
      text-align: center;
      margin: 20px 0px;
      font-family: "Playfair Display", serif;
      margin-top: 50px;
      margin-bottom: 30px;
    }
  
    input {
      width: 300px;
      font-size: 20px;
      border: 1px solid #dbdbdb;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      outline-color: #458af0;
    }
    .con {
      width: 320px;
      font-size: 15px;
      font-weight: 600;
      border: none;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      background: #458af0;
      color: white;
      margin-top: 20px;
      cursor: pointer;
    }
    .site {
      width: 320px;
      font-size: 15px;
      font-weight: 600;
      border: none;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      background: white;
      color: #458af0;
      border: 1px solid #458af0;
      margin-top: 20px;
      cursor: pointer;
    }
  }
  
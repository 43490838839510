.admin_site_page {
    .Title2 {
      font-size: 25px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      margin: 30px 30px;
      margin-bottom: 15px;
    }
  
    .boxes {
      display: flex;
      flex-wrap: wrap;
      margin: 0px 20px;
    }
  
    .box {
      width: 50%;
      max-width: 150px;
      display: block;
      margin: 20px 10px;
    }
    .box:hover {
      opacity: 0.7;
    }
    .box:active {
      opacity: 0.7;
    }
  
    .box img {
      width: 100%;
    }
  
    .box .sitename {
      font-size: 15px;
      font-weight: 500;
      margin-top: 5px;
    }
  
    .Tabs {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0px 20px;
      width: calc(100% - 40px);
      flex-wrap: wrap;
    }
    .Tabs li {
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      outline: none;
      margin: 10px;
      cursor: pointer;
    }
  
    .tab {
      font-size: 15px;
      font-weight: 400;
    }
    .react-tabs__tab--selected {
      border-bottom: 2px solid #458af0 !important;
    }
  
    .task {
        margin: 10px auto;
        margin-top: 20px;
        padding: 10px 15px;
        border: 1px solid #d6d6d6;
        width: 100%;
        max-width: 300px;
    }
  
    .taskname {
      font-size: 15px;
      margin-bottom: 10px;
    }
  
    .remaining {
      display: flex;
      align-items: center;
    }
  
    .remaining span {
      font-size: 12px;
      color: #777777;
      margin-right: 10px;
    }
  
    .used {
      display: flex;
      align-items: center;
      margin: 10px 0px;
    }
    .used span {
      font-size: 12px;
      color: #777777;
      margin-right: 10px;
    }
  
    .used input {
      width: 40px;
    }
  
    .qua button {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 5px 10px;
      margin-right: 10px;
      color: white;
    }

    .delete {
      font-size: 15px;
      border: none;
      background: #d64848;
      padding: 5px 10px;
      margin-right: 10px;
      color: white;
    }
    .tasksupername {
      font-size: 12px;
      margin-bottom: 5px;
    }
  
    .msg {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  
    .popup_equipment_back {
      width: 100%;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: #00000017;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .popup_equipment {
      width: 80%;
      max-width: 400px;
      padding: 15px;
      background: white;
      border-radius: 5px;
    }
  
    .popup_equipment .title {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  
    input {
      width: 95%;
      font-size: 15px;
      margin: 10px 0px;
      padding: 5px;
    }

    select {
      width: calc(95% + 10px);
      font-size: 15px;
      margin: 10px 0px;
      padding: 5px;
    }
  
    .popup_equipment button {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 20px;
      margin-right: 10px;
      color: white;
      margin-top: 10px;
    }
  
    .createbtn {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 15px;
      color: white;
      margin-left: 30px;
    }
    .popup_equipment button:last-child {
      background: #d64848;
    }
  
    .general {
      width: 80%;
      max-width: 400px;
      margin: 30px auto;
    }
    .generaltext {
      font-size: 15px;
      margin: 10px 0px;
  }
  

    .message {
      width: 80%;
      max-width: 400px;
      margin: 10px auto;
      border: 1px solid #cdcdcd;
      margin-bottom: 20px;
      padding: 5px 10px;
    }
  
    .messageinput {
      width: 80%;
        max-width: 400px;
        margin: 10px auto;
        border: 1px solid #cdcdcd;
        margin-bottom: 20px;
        padding: 5px 10px;
    }
  
    .nameinput {
      display: flex;
      align-items: center;
    }
  
    .msginput {
      display: flex;
      align-items: start;
    }
  
    .msginput textarea {
      width: -webkit-fill-available;
      margin-left: 10px;
    }
  
    .nameinput input {
      margin-left: 10px !important;
    }

    .messageinput select {
      margin-left: 10px;
  }
    .messageinput button{font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 20px;
      margin-right: 10px;
      color: white;
      margin-top: 10px;
    }
    .message .name {
      font-size: 15px;
      margin: 10px 0px;
  }

  .loader {
    width: 100%;
    height: 100vh;
    background: #0000004a;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader img {
    width: 35px;
}

  }
  
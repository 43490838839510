.admin_visit_page {
    .Title2 {
      font-size: 25px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      margin: 30px 30px;
      margin-bottom: 15px;
    }
  
    .boxes {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }
  
    .box {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 20px;
      margin-right: 10px;
      color: white;
      margin-top: 10px;
    }
    .box .sitename{
      color: white;
    }

 


    
    .loader {
      width: 100%;
      height: 100vh;
      background: #0000004a;
      position: fixed;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  
  .loader img {
      width: 35px;
  }
  
  }
  
.main_site_page,
.main_sites_page,
.main_user_page,
.main_admins_page,
.main_admin_page {
  a {
    text-decoration: none;
  }
  .nav {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cccccc;
  }
  .Logo {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    margin-left: 20px;
    color: #458af0;
  }

  .Logo span {
    font-size: 15px;
    margin-left: 5px;
    color: #555555;
  }

  .nav .profile {
    height: 35px;
    margin-left: auto;
    margin-right: 20px;
  }
  .profile img {
    height: 35px;
  }

  .dropdown {
    position: absolute;
    top: 40px;
    right: 10px;
    width: 140px;
    background: white;
    padding: 10px;
    display: none;
  }

  .profile:hover .dropdown {
    display: block;
  }
  .dropdown .name {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .dropdown button {
    width: 100%;
    margin: 5px 0px;
    font-size: 13px;
    padding: 5px 0px;
    border: none;
    background: #cedcff;
  }
}

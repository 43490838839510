.visit-report {
  font-family: Arial, sans-serif;
  margin: 20px;
  background-color: #f4f4f4;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 1000px;
  min-height: 1300px;
  margin: 30px auto;
  border: 1px solid black;
  border-radius: 0px;

  
.title {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title img{
  height: 150px;
  margin-right: 20px;
}


.subtitle {
  color: #555;
  margin-top: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

strong {
  color: #000;
}


#print{
  width: 320px;
font-size: 15px;
font-weight: 600;
border: none;
padding: 10px 10px;
margin: 10px auto;
display: block;
background: #458af0;
color: white;
margin-top: 20px;
cursor: pointer;
}


}

.splash_page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  
    .logo {
      font-size: 45px;
      font-weight: 800;
      text-align: center;
      color: #458af0;
    }
  }
.site_page {
    .Title2 {
      font-size: 25px;
      font-weight: 600;
      font-family: "Playfair Display", serif;
      margin: 30px 30px;
      margin-bottom: 15px;
    }
  
    .boxes {
      display: flex;
      flex-wrap: wrap;
      margin: 0px 20px;
    }
  
    .box {
      width: 50%;
      max-width: 150px;
      display: block;
      margin: 20px 10px;
    }
    .box:hover {
      opacity: 0.7;
    }
    .box:active {
      opacity: 0.7;
    }
  
    .box img {
      width: 100%;
    }
  
    .box .sitename {
      font-size: 15px;
      font-weight: 500;
      margin-top: 5px;
    }
  
    .Tabs {
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0px 20px;
      width: calc(100% - 40px);
      flex-wrap: wrap;
    }
    .Tabs li {
      border-bottom: 2px solid transparent;
      padding-bottom: 5px;
      outline: none;
      margin: 10px;
      cursor: pointer;
    }
  
    .tab {
      font-size: 15px;
      font-weight: 400;
    }
    .react-tabs__tab--selected {
      border-bottom: 2px solid #458af0 !important;
    }
  
    .general {
      margin-left: 30px;
    }
  
    .datetxt {width: 100%;max-width: 300px;margin: 5px auto;}

input.dateinput {
    width: 100%;
    max-width: 300px;
    margin: 5px auto;
    display: block;
}

.shiftbtn button{
  font-size: 15px;
  border: none;
  background: #458af0;
  padding: 10px 15px;
  color: white;
  display: block;
  margin: 20px auto;
}

    .generaltext {
      font-size: 15px;
      font-weight: 400;
      margin: 10px 0px;
    }
  
    .task {
      margin: 10px auto;
      margin-top: 20px;
      padding: 10px 15px;
      border: 1px solid #d6d6d6;
      width: 100%;
      max-width: 300px;
      background: white;
  }
  
    .task img {
      width: 100px;
      margin-top: 10px;
    }
  
    .taskname {
      font-size: 15px;
      margin-bottom: 10px;
    }
  
    .taskbtns {
      display: flex;
      align-items: center;
    }
  
    img[src='data:image/jpg;charset=utf8;base64,']{
      display: none;
    }

    .shiftbtn{
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 15px;
      color: white;
      display: block;
      margin: 20px auto;
    }

    .task button {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 15px;
      color: white;
    }
  
    .taskbtns button:nth-child(2) {
      background: #b9b316;
    }
  
    .taskbtns button:nth-child(3) {
      background: #d64848;
    }
  
    .remaining {
      display: flex;
      align-items: center;
    }
  
    .remaining span {
      font-size: 12px;
      color: #777777;
      margin-right: 10px;
    }
  
    .used {
      display: flex;
      align-items: center;
      margin: 10px 0px;
    }
    .used span {
      font-size: 12px;
      color: #777777;
      margin-right: 10px;
    }
  
    .used input {
      width: 40px;
    }
  
    .qua button {
      font-size: 15px;
      border: none;
      background: #458af0;
      padding: 5px 10px;
      margin-right: 10px;
      color: white;
    }
    .tasksupername {
      font-size: 12px;
      margin-bottom: 5px;
    }
  
    .msg {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  
    .timing {
      margin: 10px 0px;
    }
  
    .timing .title {
      font-size: 12px;
      margin-bottom: 5px;
    }
    input {
      width: 95%;
      font-size: 15px;
      margin: 10px 0px;
      padding: 5px;
    }

    select {
      width: calc(95% + 10px);
      font-size: 15px;
      margin: 10px 0px;
      padding: 5px;
    }

    
    .general {
      width: 80%;
      max-width: 400px;
      margin: 30px auto;
    }
    .generaltext {
      font-size: 15px;
      margin: 10px 0px;
  }
  

  .task2 {
    margin: 10px auto;
    margin-top: 20px;
    padding: 10px 15px;
    border: 1px solid #d6d6d6;
    width: 100%;
    max-width: 300px;
    background: white;
}

.task2 table {
  width: -webkit-fill-available;
}
.task2 td {
  display: flex;
  width: 30%;
}

.task2 tr {
  display: flex;
}

.task2 th {
  display: flex;
  width: 30%;
}
.task2 th, td {
border: 1px solid black;
border-collapse: collapse;
}

.task2 button {
  font-size: 15px;
  border: none;
  background: #458af0;
  padding: 10px 15px;
  color: white;
}
    .message {
      width: 80%;
      max-width: 400px;
      margin: 10px auto;
      border: 1px solid #cdcdcd;
      margin-bottom: 20px;
      padding: 5px 10px;
      background: white;
    }
  
    .messageinput {
      width: 80%;
        max-width: 400px;
        margin: 10px auto;
        border: 1px solid #cdcdcd;
        margin-bottom: 20px;
        padding: 5px 10px;
        background: white;
    }
  
    .nameinput {
      display: flex;
      align-items: center;
    }
  
    .msginput {
      display: flex;
      align-items: start;
    }
  
    .msginput textarea {
      width: -webkit-fill-available;
      margin-left: 10px;
    }
  
    .nameinput input {
      margin-left: 10px !important;
    }

    .messageinput select {
      margin-left: 10px;
  }
    .messageinput button{font-size: 15px;
      border: none;
      background: #458af0;
      padding: 10px 20px;
      margin-right: 10px;
      color: white;
      margin-top: 10px;
    }
    .message .name {
      font-size: 15px;
      margin: 10px 0px;
  }

  .loader {
    width: 100%;
    height: 100vh;
    background: #0000004a;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader img {
    width: 35px;
}


.noshift {
  text-align: center;
  margin-top: 100px;
  color: red;
  font-size: 20px;
  font-weight: 500;
}
  }
  
@import url('https://fonts.googleapis.com/css2?family=Gupter:wght@400;500;700&display=swap');

.report-container {
    font-family: "Gupter", serif;
    margin: 20px;
    background-color: #f4f4f4;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 1000px;
    margin: 30px auto;
    border: 1px solid black;
    border-radius: 0px;

    h1{
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h1 img{
        height: 150px;
        margin-right: 20px;
    }
    h1, h2 {
        text-align: left;
        color: black;
        font-size: 20px;

    }
    
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
    }
    
    table, th, td {
        border: 1px solid black;
    }
    
    th, td {
        padding: 0px 5px;
        text-align: left;
    }
    
    th {
        background-color: #f2f2f2;
    }
    
    td {
        background-color: #fff;
    }
    
    p {
        margin: 5px 0;
        display: flex;
        align-items: start;
        justify-content: space-between;
    }
    p span {
        width: 30%;
    }

    .printsection {
        display: flex
    ;
    }
    
    textarea.weeklyinput {
        width: 100px;
        height: 10px;
    }
    strong {
        font-weight: bold;
    }

    .imgbox {
        width: 99%;
        margin: 10px auto;
        display: flex;
        flex-wrap: wrap;
    }
    
    .box {
        width: 300px;
        border: 1px solid #d7d7d7;
    }
    
    .boxtitle {
        width: 300px;
        height: 35px;
        border-bottom: 1px solid #d7d7d7;
        display: flex;
        align-items: center;
    }
    
    .box img {
        width: 100%;
        height: 250px;
    }

    #print{
        width: 320px;
      font-size: 15px;
      font-weight: 600;
      border: none;
      padding: 10px 10px;
      margin: 10px auto;
      display: block;
      background: #458af0;
      color: white;
      margin-top: 20px;
      cursor: pointer;
    }
    
}
